/* CSS Document by Palakshi Dam -Krishna Tech */
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Playball);
@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");

body {
  font-family: "Nunito", sans-serif !important;
  margin: 0;
}
#main {
  width: 100%;
  height: auto;
  float: left;
}
#main-inner {
  width: 80%;
  height: auto;
  margin: 0 auto;
  overflow: auto;
}
#main-inner1 {
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: auto;
}

#welcome {
  width: 100%;
  height: auto;
  float: left;
  margin: 3% 0;
}
#welcome h1 {
  color: #666666;
  text-align: center;
  font-family: "Playball", cursive;
  width: 100%;
  height: auto;
  float: left;
  padding-bottom: 6%;
  margin: 0;
}
#welcome p {
  font-size: 14px;
  color: #666666;
  text-align: center;
  line-height: 160%;
  width: 70%;
  margin: 0 15%;
  padding: 0;
}

#form_div {
  width: 100%;
  height: auto;
  float: left;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  margin: 0 0 1% 0;
  background: #ffffff;
  overflow: hidden;
}
#form_div .left {
  width: 20%;
  height: auto;
  float: left;
  padding: 0%;
  background: #f2f1f1;
}
#form_div .left h3 {
  text-align: center;
  color: #7a0101;
  margin: 4% 0;
}
#form_div .left p {
  text-align: center;
  color: #666666;
  margin: 4% 0;
}
#form_div .right {
  width: 80%;
  height: auto;
  float: left;
}
#form_div .right .con {
  width: 20%;
  height: auto;
  float: left;
  margin: 1%;
}
#form_div .right .con1 {
  width: 11%;
  height: auto;
  float: left;
  margin: 5% 1% 0 0;
}
#form_div .right .con p {
  margin: 5% 0;
}
#form_div .right .box {
  width: 100%;
  height: 32px;
  border: #cccccc 1px solid;
  padding: 1.5%;
  color: #666666;
  border-radius: 3px;
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  transition: 0.5s;
}
#form_div .right .box:hover {
  border: #0099ff 1px solid;
}
#form_div option {
  padding: 1%;
  border: #999999 1px solid;
}
#form_div .right .box1 {
  width: 100%;
  height: 32px;
  border: #cccccc 1px solid;
  padding: 1.5%;
  color: #333333;
  border-radius: 3px;
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  transition: 0.5s;
}
#form_div .right .box1:hover {
  border: #0099ff 1px solid;
}
#form_div .right .submit {
  width: 100%;
  height: auto;
  float: left;
  padding: 3% 0;
  text-align: center;
  background-color: #7a0101;
  text-decoration: none;
  color: #ffffff;
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}
#form_div .right .submit:hover {
  background-color: #c00000;
}
.pcolor {
  color: #ffffff;
}

.services_bg {
  background: #f7f7f6;
}
#services {
  width: 100%;
  height: auto;
  float: left;
  margin: 3% 0;
}
#services h1 {
  color: #666666;
  width: 100%;
  height: auto;
  float: left;
  margin: 0;
}
#services b {
  width: 30%;
  float: left;
  border-bottom: 2px #01b367 solid;
  color: white;
  font-size: 1px;
}
#services .con {
  width: 18%;
  height: auto;
  float: left;
  margin: 2% 1%;
}
#services .con img {
  width: 50%;
  height: auto;
  float: left;
  margin: 1% 25% 3% 25%;
}
#services .con h4 {
  color: #666666;
  text-align: center;
  font-family: "Playball", cursive;
}

#banner {
  width: 100%;
  height: auto;
  float: left;
  margin: 20% 0;
}
#banner h1 {
  color: white;
  width: auto;
  height: auto;
  float: left;
  margin: 0;
  padding: 5% 0 3% 0;
  font-family: "Playball", cursive;
}
#banner strong {
  font-size: 70px;
  font-weight: normal;
}
#banner p {
  color: white;
  font-size: medium;
  width: 100%;
  float: left;
  color: #dadad9;
  line-height: 160%;
}

#footer {
  width: 100%;
  height: auto;
  float: left;
  margin: 2% 0 1% 0;
}
#footer .con {
  width: 58%;
  height: auto;
  float: left;
  margin-right: 7%;
}
#footer h3 {
  color: white;
  margin-bottom: 2%;
}
#footer .con p {
  color: #c0c0c0;
  border-right: 1px #999999 solid;
  width: auto;
  float: left;
  padding-right: 8%;
  margin-right: 8%;
  font-size: small;
}
#footer .con span {
  color: #c0c0c0;
  font-size: small;
}
#footer .con1 {
  width: 35%;
  height: auto;
  float: right;
}
#footer .icon {
  border: #4c4c4c 2px solid;
  margin: 2% 1%;
  width: 30px;
  height: 30px;
  float: left;
  padding: 1%;
  background: #f1f1f1;
  border-radius: 50px;
  transition: 0.5s;
}
#footer .icon:hover {
  border: #ef5502 2px solid;
}
#footer .img {
  width: 70%;
  height: auto;
  float: left;
  margin: 15%;
  transition: 0.5s;
}
#footer .img:hover {
  opacity: 0.7;
}
#footer p {
  width: auto;
  float: left;
}
#footer .lower {
  width: 100%;
  float: left;
  border-top: 1px #666666 solid;
  margin-top: 3%;
  padding-top: 2%;
}
#footer .lower p {
  float: left;
  color: #c0c0c0;
  font-size: small;
}
#footer .lower .p {
  float: right;
}
#footer .lower a {
  color: #efaa1f;
}

.banner {
  color: #ffffff;
  width: 100%;
  float: left;
  padding: 12% 0 0 0;
}
.banner h1 {
  text-align: left;
  float: left;
  padding: 3% 0;
  font-family: "Playball", cursive;
  color: #b87b01;
}
.banner .right {
  width: auto;
  float: right;
  padding: 4% 0;
  color: black;
}
.banner a {
  text-align: right;
  font-size: small;
  transition: 0.5s;
}
.banner a:hover {
  color: #666666;
}
.banner span {
  color: #333333;
  font-size: small;
  text-align: right;
}

#static {
  width: 100%;
  height: auto;
  float: left;
  margin: 2% 0;
}
#static img {
  width: auto;
  height: auto;
  float: left;
  margin: 0 2% 2% 0;
}
#static p {
  font-size: 13px;
  line-height: 150%;
  color: #333333;
  text-align: justify;
  margin: 1% 0;
}
#static ul {
  margin: 0;
  padding: 0;
  margin: 2% 0;
}
#static ul li {
  font-size: 13px;
  color: #333333;
  text-align: justify;
  padding: 0.2% 0 0.5% 2%;
}

#gallery {
  width: 100%;
  height: auto;
  float: left;
  margin: 2% 0;
}
#gallery .con {
  width: 23%;
  height: auto;
  float: left;
  margin: 1%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}
#gallery .con img {
  border: #cccccc 3px solid;
  width: 100%;
  height: auto;
  float: left;
  transition: 0.5s;
}
#gallery .con img:hover {
  border: #fea801 3px solid;
}

#contact {
  width: 100%;
  height: auto;
  float: left;
  margin: 2% 0;
}
#contact h2 {
  color: #f89b1c;
  margin: 0 0 3% 0;
  font-weight: normal;
}
#contact h3 {
  color: #333333;
}
#contact .left {
  width: 40%;
  height: auto;
  float: left;
  margin-bottom: 3%;
}
#contact .left p {
  color: #666666;
  line-height: 150%;
  font-size: 14px;
  margin: 0;
}

.map {
  width: 59%;
  height: auto;
  float: left;
  margin: 0.5%;
  border: #666666 1px solid;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

#rooms {
  width: 100%;
  height: auto;
  float: left;
  margin: 3% 0;
}
#rooms .con {
  width: 60%;
  height: auto;
  float: left;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: 1% 20%;
}
#rooms .con h3 {
  text-align: center;

  color: #ffffff;
  margin: 0;
  padding: 2.5% 0;
  font-style: italic;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
}
#rooms .con .tot {
  width: 100%;
  height: auto;
  float: left;
  padding: 2% 0;
  border-bottom: 1px #993300 dashed;
}
#rooms .con .tot:last-child {
  border-bottom: none;
}
#rooms .con p {
  color: #ffffff;
  padding: 3% 1%;
  font-size: large;
  text-align: center;
  float: left;
  width: 20%;
  font-weight: bold;
}
#rooms .con .right {
  width: 80%;
  height: auto;
  float: right;
}
#rooms .con h4 {
  width: 33%;
  float: left;
  text-align: center;
  color: #7c4d0e;
  font-weight: bold;
  border-bottom: 1px #fdf0f0 solid;
  padding-bottom: 1%;
}
#rooms .con span {
  width: 33%;
  float: left;
  text-align: center;
  color: #666666;
  padding: 1% 0;
}
#rooms .con b {
  float: right;
  color: black;
  margin: 1% 1% 0 1%;
  font-weight: normal;
  font-size: small;
}

@media all and (max-width: 768px),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
  #main-inner {
    width: 98%;
    height: auto;
    margin: 0 auto;
    overflow: auto;
  }

  #welcome {
    margin: 5% 0;
  }
  #welcome h1 {
    padding-bottom: 25%;
    margin: 0;
  }
  #welcome p {
    font-size: small;
    color: #666666;
    text-align: justify;
    line-height: 160%;
    width: 96%;
    margin: 0 2%;
    padding: 0;
  }

  #form_div {
    width: 99%;
    height: auto;
    float: left;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    margin: 0 0 3% 0;
    background: #ffffff;
  }
  #form_div .left {
    width: 100%;
    height: auto;
    float: left;
    padding: 1%;
    background: #f2f1f1;
  }
  #form_div .left h3 {
    text-align: center;
    color: #7a0101;
    margin: 4% 0;
  }
  #form_div .left p {
    text-align: center;
    color: #666666;
    margin: 0;
  }
  #form_div .right {
    width: 100%;
    height: auto;
    float: left;
    padding: 1% 1% 4% 1%;
  }
  #form_div .right .con {
    width: 98%;
    height: auto;
    float: left;
    margin: 0 1%;
  }
  #form_div .right .con1 {
    width: 98%;
    height: auto;
    float: left;
    margin: 0 1%;
  }
  #form_div .right .con p {
    margin: 5% 0;
  }
  #form_div .right .box {
    width: 100%;
    height: 35px;
    border: #cccccc 1px solid;
    padding: 1.5%;
    color: #666666;
    border-radius: 3px;
    margin: 0;
    font-family: "Roboto", sans-serif;
    transition: 0.5s;
  }
  #form_div .right .box:hover {
    border: #0099ff 1px solid;
  }
  #form_div option {
    padding: 1%;
    border: #999999 1px solid;
  }
  #form_div .right .box1 {
    width: 100%;
    height: 32px;
    border: #cccccc 1px solid;
    padding: 1.5%;
    color: #333333;
    border-radius: 3px;
    margin: 0;
    font-family: "Roboto", sans-serif;
    transition: 0.5s;
  }
  #form_div .right .box1:hover {
    border: #0099ff 1px solid;
  }
  #form_div .right .submit {
    width: 100%;
    height: auto;
    float: left;
    padding: 4% 0;
    background-color: #7a0101;
    text-decoration: none;
    color: #ffffff;
    margin: 6% 0 0 0;
    font-family: "Roboto", sans-serif;
    border: none;
    cursor: pointer;
    transition: 0.5s;
  }
  #form_div .right .submit:hover {
    background-color: #c00000;
  }

  .services_bg {
    background: #e7e7e7;
  }
  #services {
    margin: 5% 0;
  }
  #services h1 {
    color: #666666;
    width: 98%;
    height: auto;
    float: left;
    margin: 1%;
  }
  #services b {
    width: 50%;
    float: left;
    border-bottom: 2px #01b367 solid;
    color: #e7e7e7;
    font-size: 1px;
  }
  #services .con {
    width: 90%;
    height: auto;
    float: left;
    margin: 4% 5%;
    background: #f4f3f3;
    padding: 5% 0;
  }
  #services .con h4 {
    color: #666666;
    text-align: center;
    font-family: "Playball", cursive;
    font-size: x-large;
  }

  #banner h1 {
    padding: 25% 0 3% 0;
    line-height: 130%;
    text-align: center;
  }

  #footer {
    margin: 5% 0;
  }
  #footer .con {
    width: 98%;
    height: auto;
    float: left;
    margin: 2% 1%;
  }
  #footer h3 {
    color: white;
    margin-bottom: 4%;
  }
  #footer .con p {
    color: #c0c0c0;
    border-right: none;
    width: 100%;
    float: left;
    padding-bottom: 2%;
    margin-bottom: 2%;
    text-align: center;
  }
  #footer .con span {
    color: #c0c0c0;
    text-align: center;
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
  }
  #footer .con1 {
    width: 98%;
    height: auto;
    float: right;
    margin: 2% 1%;
  }
  #footer .icon {
    border: #4c4c4c 2px solid;
    margin: 2% 1%;
    width: 40px;
    height: 40px;
    float: left;
    padding: 1%;
    background: #f1f1f1;
    border-radius: 50px;
    transition: 0.5s;
  }
  #footer .lower {
    width: 100%;
    float: left;
    border-top: 1px #666666 solid;
    margin-top: 4%;
    padding-top: 2%;
  }
  #footer .lower p {
    float: left;
    color: #c0c0c0;
    font-size: small;
    width: 100%;
    margin: 0.5% 0;
    text-align: center;
  }

  .banner {
    color: #ffffff;
    width: 100%;
    float: left;
    padding: 10% 0;
  }
  .banner h1 {
    text-align: center;
    width: 100%;
    float: left;
    padding: 3% 0;
    font-family: "Playball", cursive;
    color: #b87b01;
  }
  .banner .right {
    width: 100%;
    float: left;
    padding: 4% 0;
    text-align: center;
    color: black;
  }

  #static img {
    width: 90%;
    height: auto;
    float: left;
    margin: 1% 5%;
  }
  #static p {
    font-size: small;
    line-height: 150%;
    color: #333333;
    text-align: justify;
    margin: 1%;
  }
  #static ul {
    margin: 0;
    padding: 0;
    margin: 2% 0;
  }
  #static ul li {
    font-size: small;
    color: #333333;

    text-align: justify;
    padding: 0.2% 0 0.5% 9%;
    width: 95%;
    float: left;
    margin: 1% 0;
  }

  #gallery .con {
    width: 96%;
    height: auto;
    float: left;
    margin: 2%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  }

  #contact .left {
    width: 98%;
    height: auto;
    float: left;
    margin: 1% 1% 3% 1%;
    background: #cccccc;
  }
  #contact .left p {
    color: #666666;
    line-height: 150%;
    font-size: medium;
    margin: 0;
  }

  .map {
    width: 98%;
    height: auto;
    float: left;
    margin: 2.5% 0.5%;
    border: #666666 1px solid;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  }

  #rooms .con {
    width: 99%;
    height: auto;
    float: left;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    margin: 1% 0.5%;
  }
  #rooms .con p {
    color: #ffffff;
    padding: 3% 1%;
    font-size: x-small;
    text-align: center;
    float: left;
    width: 20%;
    font-weight: bold;
  }
  #rooms .con h4 {
    width: 33%;
    float: left;
    font-size: small;
    text-align: center;
    color: #7c4d0e;
    font-weight: bold;
    border-bottom: 1px #fdf0f0 solid;
    padding-bottom: 1%;
  }
  #rooms .con span {
    width: 33%;
    float: left;
    font-size: small;
    text-align: center;
    color: #666666;
    padding: 1% 0;
  }
  #rooms .con b {
    float: right;
    color: black;
    margin: 1% 1% 0 1%;
    font-weight: normal;
    font-size: x-small;
  }
  #rooms .con .tot {
    width: 100%;
    height: auto;
    float: left;
    padding: 4% 0;
    border-bottom: 1px #993300 dashed;
  }
}
