/*  ============= WEB BROWSER RESETS ============ */
* { margin: 0; padding: 0; border: 0; box-sizing: border-box }
html { height: 100% }
body { min-height: 100%; min-width: 320px; max-width: 1600px; margin-left: auto; margin-right: auto }  
img { width: 100%; height: auto; display: block }

body { font-family: 'Playfair Display', sans-serif }


/* ========== MAIN CONTENT CONTAINERS ========= */
/* Desktops */
@media all and (min-width:768px) { .container-block { padding: 2% 8% } }

/* Mobiles */
@media all and (max-width:767px) { .container-block { padding: 11% 8% } }


/* ========== FLEXBOX PARENT AND CHILD COLUMN ELEMENTS ========= */

/* Flexbox parent container */
.container-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
}

/* Desktops: flexbox child columns */
@media all and (min-width: 768px) {
    .item-col-3 { width: 31% }
}

/* Mobiles: flexbox child columns */
@media all and (max-width: 767px) {
    .item-col-3 { width: 100% }
    /* Vertical spacing under child columns */
    .item-col-3 { margin-bottom: 32px }
    /* No vertical spacing under last child column */
    .item-col-3:last-child  { margin-bottom: 0 } 
}

/* Desktops: inner padding for child columns */
@media all and (min-width: 768px) {
    .item-col-3.item-col-padding { padding: 1.8% 2% 2.2% 2% }
}

/* Mobiles: inner padding for child columns  */
@media all and (max-width: 767px) {
    .item-col-3.item-col-padding { padding: 6.5% 7% 7% 7% }
}


/* =========== SUB-HEADINGS AND PARAGRAPHS ============*/

/* Sub-headings inside flexbox child columns */
.item-col-3 h3 { 
    font-size: calc(22px + (38 - 22) * ((100vw - 320px) / (1600 - 320)));
    color:#d1274b;
    margin-bottom: 2px;
}

  

/* Paragraphs inside flexbox child columns */
.item-col-3 p { 
    font-size: calc(16px + (15 - 10) * ((100vw - 320px) / (1600 - 320)));
    
}


/* Desktops: paragraph line-spacing and bottom margin */
@media all and (min-width: 768px) {
    .item-col-3 p { line-height: 1.6; margin-bottom: 20px }
}

/* Mobiles: paragraph line-spacing and bottom margin */
@media all and (max-width: 767px) {
    .item-col-3 p { line-height: 1.4; margin-bottom: 14px }
}

/* No vertical spacing under final item inside child columns */
.item-col-3 *:last-child { margin-bottom: 0 }

/* Images inside child columns */
.item-col-3 img { margin-bottom: 20px }


/* ========= COLOURED BACKGROUNDS =========== */
.bg-light { background-color: #f5f5f5 }
.bg-dark  { background-color: #C71585 }


/* Colours for sub-headings and paragraphs */
.container-flexbox.bg-dark .item-col-3 * { color: #fff }
.container-flexbox .item-col-3.bg-dark * { color: #fff }

.item-col-3:hover {
    background-color: bisque;
  }
