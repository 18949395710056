/********** Template CSS **********/
#main {
  width: 100%;
  height: auto;
  float: left;
}
#main-inner {
  width: 80%;
  height: auto;
  margin: 0 auto;
  overflow: auto;
}
#main-inner1 {
  width: 100%;
  height: auto;
  margin: 0 auto;
  overflow: auto;
}
:root {
  --primary: #32c36c;
  --light: #f6f7f8;
  --dark: #1a2a36;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: 0.5s;
}

.navbar .navbar-brand,
.navbar a.btn {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: var(--dark);
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/
.owl-carousel-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
    position: relative;
    min-height: 500px;
  }

  .header-carousel .owl-carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header-carousel .owl-carousel-item p {
    font-size: 16px !important;
  }
}

.header-carousel .owl-dots {
  position: absolute;
  width: 60px;
  height: 100%;
  top: 0;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-carousel .owl-dots .owl-dot {
  position: relative;
  width: 45px;
  height: 45px;
  margin: 5px 0;
  background: #ffffff;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.9);
  border-radius: 45px;
  transition: 0.5s;
}

.header-carousel .owl-dots .owl-dot.active {
  width: 60px;
  height: 60px;
}

.header-carousel .owl-dots .owl-dot img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 2px;
  border-radius: 45px;
  transition: 0.5s;
}

.page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(../img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

/*** About ***/
@media (min-width: 992px) {
  .container.about {
    max-width: 100% !important;
  }

  .about-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .about-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .about-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.service-icon {
  position: relative;
  margin: -50px 0 25px 0;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: #ffffff;
  border-radius: 100px;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.service-item:hover .service-icon {
  color: #ffffff;
  background: var(--primary);
}

/*** Feature ***/
@media (min-width: 992px) {
  .container.feature {
    max-width: 100% !important;
  }

  .feature-text {
    padding-left: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .feature-text {
    padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .feature-text {
    padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Project Portfolio ***/
#portfolio-flters li {
  display: inline-block;
  font-weight: 500;
  color: var(--dark);
  cursor: pointer;
  transition: 0.5s;
  border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
  color: var(--primary);
  border-color: var(--primary);
}

.portfolio-img {
  position: relative;
}

.portfolio-img::before,
.portfolio-img::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  background: var(--dark);
  transition: 0.5s;
}

.portfolio-img::before {
  left: 50%;
}

.portfolio-img::after {
  right: 50%;
}

.portfolio-item:hover .portfolio-img::before {
  width: 51%;
  left: 0;
}

.portfolio-item:hover .portfolio-img::after {
  width: 51%;
  right: 0;
}

.portfolio-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 1;
  transition: 0.5s;
}

.portfolio-item:hover .portfolio-btn {
  opacity: 1;
  transition-delay: 0.3s;
}

/*** Quote ***/
@media (min-width: 992px) {
  .container.quote {
    max-width: 100% !important;
  }

  .quote-text {
    padding-right: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .quote-text {
    padding-right: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .quote-text {
    padding-right: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.team-item img {
  border-radius: 8px 60px 0 0;
}

.team-item .team-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  transition: 0.5s;
}

/*** Testimonial ***/
.testimonial-carousel::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 200px;
  }
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
    width: 300px;
  }
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 350px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: 0.5s;
  z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
  width: 300px;
  opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  color: var(--primary);
  font-size: 45px;
  transition: 0.5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}

.testimonial-carousel .testimonial-img img {
  width: 100px;
  height: 100px;
}

.testimonial-carousel .testimonial-img .btn-square {
  position: absolute;
  bottom: -19px;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-carousel .owl-item .testimonial-text {
  margin-bottom: 30px;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transform: scale(0.8);
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  transform: scale(1);
}

/*** Contact ***/
@media (min-width: 992px) {
  .container.contact {
    max-width: 100% !important;
  }

  .contact-text {
    padding-left: calc(((100% - 960px) / 2) + 0.75rem);
  }
}

@media (min-width: 1200px) {
  .contact-text {
    padding-left: calc(((100% - 1140px) / 2) + 0.75rem);
  }
}

@media (min-width: 1400px) {
  .contact-text {
    padding-left: calc(((100% - 1320px) / 2) + 0.75rem);
  }
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  border-radius: 38px;
  transition: 0.3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
  border-color: var(--light);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #9b9b9b;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: #ffffff;
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .copyright a:hover {
  color: var(--primary);
}
.responsive-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .responsive-image {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .responsive-image {
    max-width: 100%;
    max-height: 60%;
  }
}
.iconDetails {
  line-height: 150px;
  padding: 40px;
  top: 20rem;
  text-align: center;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
}
.responsive-three-column-grid {
  display: block;
}

/* columns */
.responsive-three-column-grid > * {
  padding: 1rem;
}

/* tablet breakpoint */
@media (min-width: 768px) {
  .responsive-three-column-grid {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.zoom {
  transition: transform 0.2s; /* Animation */
  position: fixed;
  top: 0px;
  left: 0px;
  width: "400px";
  height: "400px";
}

.zoom:hover {
  transform: scale(
    1.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.cimg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
